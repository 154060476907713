import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import VueI18n from './locale/index'
import tabBar from './components/tabBar.vue'
import 'amfe-flexible'
import * as echarts from 'echarts'
import axios from 'axios'

// import { Field } from "vant"

const app = createApp(App);
app.config.globalProperties.$echarts = echarts;
app.config.globalProperties.$axios = axios
const pinia = createPinia();

// app.directive('no-space', {
//     // 当被绑定的元素插入到 DOM 中时...
//     mounted(el) {
//         // 添加事件监听器以阻止空格输入
//         el.addEventListener('keydown', (e) => {
//             if (e.code === 'Space') {
//                 e.preventDefault();
//             }
//         });
//     },
//     // 当绑定的元素从 DOM 中移除时...
//     unmounted(el) {
//         // 清理/移除事件监听器
//         el.removeEventListener('keydown');
//     }
// });

app.directive('trim', {
    bind: function (el) {
      el.addEventListener('input', function (e) {
        e.target.value = e.target.value.trim();
      });
    },
    // 需要更新时保持一致
    componentUpdated: function (el) {
      el.value = el.value.trim();
    }
  });

// app.component(Field, {
//     extends: Field,
//     props: {
//       formatter: {
//         type: Function,
//         default: function (value) {
//           return value.replace(/^[^\s]*$/g, "");
//         },
//       },
//     },
//   })

app.use(store).use(router).use(Vant).use(VueI18n).use(pinia).component('tabBar',tabBar).mount('#app')
