<template>
  <router-view />
</template>
<script>
// import { onMounted, ref, onUnmounted } from "vue";
// import WebSocketService  from "./api/websocket";
export default {
  // setup() {
  //   const receivedData = ref('');
  //   let socketService;

  //   const onMessageReceived = (data)=> {
  //     receivedData.value = data
  //   }


  //   onMounted(()=> {
  //     socketService = new WebSocketService('wss://api-aws.huobi.pro/ws', onMessageReceived);
  //     socketService.connect();
  //     // socketService.sendMessage(JSON.stringify({ sub: "bnbusdt", period: '1min',size:'150' }))
  //   });

  //   onUnmounted(() => {
  //     if (socketService) {
  //       socketService.stopHeartbeat();
  //     }
  //   });

  //   return {
  //     receivedData,
  //   };
  // },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 16px;
  background-color: #333333;
  ::-webkit-scrollbar {
    width: 0;
  }
}
@media screen and (min-width: 750px) {
    #app {
      width: 375px;
      margin: 0 auto;
      
    }
}

</style>
